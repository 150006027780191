<template>
  <div class="content">
    <v-header></v-header>
    <div class="form">
        <div class="list">
            <p>姓名</p>
            <el-input v-model="con.name" placeholder="请输入您的姓名" /> 
        </div>

        <div class="list">
            <p>手机号</p>
            <el-input v-model="con.phone" placeholder="请输入您的手机号" /> 
        </div>

        <div class="list">
            <p>反馈内容</p>
            <el-input  v-model="con.data" type="textarea" :rows="5" placeholder="请输入您的留言内容" /> 
        </div>

        <div class="list">
            <el-button type="primary" @click="feedback">立即反馈</el-button>
        </div>
    </div>
    <div
      class="footer"
      style="
        font-size: 14px;
        width: 95%;
        margin: auto;
        border-top: 1px solid #f5f3f7;
        padding-top: 10px;
        text-align: center;
        font-weight: 400;
      "
    >
      Copyright ©2023-2024 德州众搜信息技术有限公司
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
        >鲁ICP备2023050409号-1</a
      >
    </div>
  </div>
</template>
        
  <script>
import { save } from '@/api/data'
import header from "@/components/header";
export default {
  components: {
    "v-header": header,
  },
  data() {
    return {
        con: {
            name: '',
            phone: '',
            data: ''
        }
    };
  },
  created() {},
  methods: {
    feedback() {
        save(this.con).then(res => {
            if(res.code == 200) {
                alert(res.msg)
            } else {
                alert(res.msg)
            }
        })
    }
  },
};
</script>
        
  <style lang="less" scoped>
@import url("./index.less");
</style>